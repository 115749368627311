import '../../static/css/reset.css'
import '../../static/css/wrapper.css'
import '../../static/css/header.css'
import '../../static/css/breadcrumb.css'
import '../../static/css/contact.css'
import '../../static/css/footer.css'
import React, { useState, useCallback } from 'react'
import { Header } from '../components/Header'
import { Breadcrumb } from '../components/Breadcrumb'
import { Footer } from '../components/Footer'
import Seo from '../components/Seo'
import { url } from '../config/domain'
import animateScroll from 'react-scroll/modules/mixins/animate-scroll'

const scrollHeading = (className: string) => {
  const targetElem = document.getElementById(className)
  if (targetElem) {
    const rect = targetElem.getBoundingClientRect()
    animateScroll.scrollTo(rect.top + document.documentElement.scrollTop - 5)
  }
}

export default function ContactUser({ location }: any): JSX.Element {
  const locatePath = location.pathname.split('/')

  const [name, setName] = useState(''),
    [email, setEmail] = useState(''),
    [confirmEmail, setConfirmEmail] = useState(''),
    [content, setContent] = useState(''),
    [emailValidation, setEmailValidation] = useState(false)

  const inputName = useCallback(
    (event) => {
      setName(event.target.value)
    },
    [setName]
  )
  const inputEmail = useCallback(
    (event) => {
      setEmail(event.target.value)
    },
    [setEmail]
  )
  const inputConfirmEmail = useCallback(
    (event) => {
      setConfirmEmail(event.target.value)
    },
    [setConfirmEmail]
  )
  const inputContent = useCallback(
    (event) => {
      setContent(event.target.value)
    },
    [setContent]
  )

  const kindList = [
    {
      value: 'サービスについて',
      name: 'entry.590591164',
    },
    {
      value: '記事の内容について',
      name: 'entry.590591164',
    },
    {
      value: '権利侵害申告',
      name: 'entry.590591164',
    },
    {
      value: '記事の作成について',
      name: 'entry.590591164',
    },
    {
      value: 'その他',
      name: 'entry.590591164',
    },
  ]

  const validationName = 'contact__validation'

  const checkValidation = (e: any) => {
    if (confirmEmail !== email) {
      setEmailValidation(true)
      scrollHeading(validationName)
      e.preventDefault()
    } else {
      setEmailValidation(false)
    }
  }

  return (
    <div>
      <div className="wrapper">
        <Seo
          title={'お問い合わせ - 個人の方'}
          description={
            'KURORO BLOGを運営するためのお問い合わせ - 個人の方詳細ページです。KURORO BLOGは、「モノづくりから始まるエンジニア」をコンセプトに、プログラミングに関心を持ってもらうための情報共有サイトです。'
          }
          url={url + 'contacts/user/'}
          noIndex={true}
          contentType="article"
        />
        <Header location={locatePath} />
        <h1 className="contact__h1">お問い合わせ - 個人の方</h1>

        <iframe name="dummyIframe" style={{ display: 'none' }} onLoad={() => (window.location.href = '/thanks/')} />

        <form
          action="https://docs.google.com/forms/u/0/d/e/1FAIpQLSeHFlh-Cbhaej2yL8Ffk1dSCl3-QcgvKmBoIsNng3lGZli-Gw/formResponse"
          method="POST"
          encType="multipart/form-data"
          target="dummyIframe"
        >
          <div className="contact__roundBox">
            <p>
              お問い合わせいただきました内容によっては、お答えできない場合やお時間がかかる場合がございます。
              <br />
              <br />
              また、土日祝日にいただいたお問い合わせにつきましては、翌営業日以降のご返信となりますので、あらかじめご了承ください。
            </p>
          </div>

          <div className="contact__roundBox contact__mobile">
            <div className="contact__section">
              <p className="contact__heading">
                <label>
                  お問い合わせの種類
                  <span className="contact__badge contact__badge-required">必須</span>
                </label>
              </p>

              <div className="contact__sectionBody">
                {kindList.map(
                  (
                    kind: {
                      name: string
                      value: string
                    },
                    idx: number
                  ) => {
                    return (
                      <div className="contact__listItem" key={idx}>
                        <label className="contact__listLabel">
                          <input type="radio" value={kind.value} required={true} name={kind.name} />
                          {kind.value}
                        </label>
                      </div>
                    )
                  }
                )}
              </div>
            </div>

            <div className="contact__section">
              <p className="contact__heading">
                <label>
                  お名前<span className="contact__badge contact__badge-required">必須</span>
                </label>
              </p>

              <div className="contact__sectionBody">
                <input
                  type="text"
                  className="contact__input"
                  name="entry.1805080354"
                  placeholder="お名前を正しくご入力ください。"
                  value={name}
                  onChange={inputName}
                  required={true}
                  onKeyPress={(e) => {
                    if (e.key == 'Enter') {
                      e.preventDefault()
                    }
                  }}
                />
              </div>
            </div>

            <div className="contact__section">
              <p className="contact__heading">
                <label>
                  Eメールアドレス<span className="contact__badge contact__badge-required">必須</span>
                </label>
              </p>

              <div className="contact__sectionBody">
                {emailValidation && <p id={validationName}>※メールアドレスが一致しません。再度ご入力ください。</p>}
                <p className="contact__caution">
                  ※携帯電話のメールアドレスをご利用の際、セキュリティ設定などによってメールが受信できない場合がありますので、ご注意ください。
                </p>
                <input
                  type="email"
                  className="contact__input"
                  name="entry.125596601"
                  placeholder="メールアドレス(半角英数字)"
                  value={email}
                  onChange={inputEmail}
                  required={true}
                  onKeyPress={(e) => {
                    if (e.key == 'Enter') {
                      e.preventDefault()
                    }
                  }}
                />
                <input
                  type="email"
                  className="contact__input"
                  name="entry.758398345"
                  placeholder="メールアドレス(確認用)"
                  value={confirmEmail}
                  onChange={inputConfirmEmail}
                  required={true}
                  onKeyPress={(e) => {
                    if (e.key == 'Enter') {
                      e.preventDefault()
                    }
                  }}
                />
              </div>
            </div>

            <div className="contact__section">
              <p className="contact__heading">
                <label>
                  お問い合わせ内容<span className="contact__badge contact__badge-required">必須</span>
                </label>
              </p>

              <div className="contact__sectionBody">
                <div className="contact__textareaContainer">
                  <textarea
                    className="contact__textarea"
                    rows={7}
                    cols={30}
                    name="entry.814453882"
                    value={content}
                    onChange={inputContent}
                    required={true}
                    onKeyPress={(e) => {
                      if (e.key == 'Enter') {
                        e.preventDefault()
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="contact__roundBox">
            <p>
              KURORO
              BLOGは、お客様より取得する個人情報を、お問い合わせいただいたご質問やご要望へ回答する目的でのみ利用いたします。なお、KURORO
              BLOGの個人情報保護に関する基本方針については、以下の
              <a href="/privacy/">プライバシーポリシー</a>をご確認ください。
            </p>
          </div>

          <div className="contact__control">
            <p>記入内容に問題がなければ「送信する」ボタンを押してください。</p>
            <div className="contact__controlBtn">
              <button type="submit" className="contact__btn" onClick={(e) => checkValidation(e)}>
                送信する
              </button>
            </div>
          </div>
        </form>

        <Breadcrumb
          breadcrumb={[
            {
              link: '/',
              text: 'KURORO BLOG',
            },
            {
              link: '/contacts/',
              text: 'お問い合わせ一覧',
            },
            {
              link: '/contacts/company/',
              text: 'お問い合わせ - 個人の方',
            },
          ]}
        />
        <Footer />
      </div>
    </div>
  )
}
